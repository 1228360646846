<template>
  <!-- 跳转大转盘（原有） 线上type =2  -->
  <div class="luckdraw" :alt="is_double">
    
    <!-- 单倍 -->
    <div class="luckdraw_bg" v-if="is_double == 0" alt="单倍">
      <img
        src="../../assets/luckdraw_new/luckbg.png"
        alt class="luckbg" />
      <img src="../../assets/luckdraw_new/btn1.png" alt
        class="luckydraw_log" @click="go_list" />
      <div class="my_jf_d">
        <p class="my_jf" v-if="userinfo">{{ userinfo.amount }}</p>
        <img src="../../assets/luckdraw_new/btn2.png" alt
          class="my_hint" @click="gohint()" />
        <div class="godiamonds" @click="godiamonds()"></div>
      </div>
      <div class="luckdraw_box">
        <div class="animation_box turntable" ref="turntable">
          <img src="../../assets/luckdraw_new/pan.png" alt
            class="luckdraw_pan" />
        </div>
        <img v-if="is_alert == 1"
          src="../../assets/luckdraw_new/button.png" alt
          class="luckdraw_btn" @click="axiosBtn ? show4 = true : ''" />
        <img v-else
          src="../../assets/luckdraw_new/button.png" alt
          class="luckdraw_btn" @click="axiosBtn ? show = true : ''" />
      </div>
      <div class="rule-d">
        <div class="rule" v-html="rule">{{ rule }}</div>
      </div>
    </div>
    <!-- 领取成功 -->
    <div class="layer-bg" v-if="success">
      <div class="layer">
        <h5 class="hint_title">领取成功！</h5>
        <div class="layer-btns">
          <div class="btns" @click="success = false; show = true;">
            <span class="btn_one_zh">再次抽奖</span>
            <span class="btn_one_en">Try Again</span>
          </div>
          <div class="btns btn2" @click="look">
            <span class="btn_one_zh">查看抽奖记录</span>
            <span class="btn_one_en">Check Record</span>
          </div>
        </div>
        <img src="../../assets/luckdraw_new/close.png"
          class="layer-close" @click="success = false" />
      </div>
    </div>
    <!-- 中奖弹窗 -->
    <div class="layer-bg" v-if="show3 || show1">
      <div class="layer">
        <div class="hint_title">恭喜！您已抽中</div>
        <div class="wintheprize_tit">{{ wintheprize.prize_name }} <span v-if="wintheprize_number == 2">X2</span></div>
        <p class="hint_en">Congrats! You've won {{ wintheprize.prize_name_en }}</p>
        <!-- 1.卡卷  2.优惠卷  3.积分 4.实物 -->
        <img src="../../assets/luckdraw_new/layer-jifen.png"
          class="layer-img" v-if="actives.is_coupon == 3" />
        <img src="../../assets/luckdraw_new/layer-xuni.png"
          class="layer-img" v-if="actives.is_coupon == 1 || actives.is_coupon == 2" />
        <img src="../../assets/luckdraw_new/layer-shiwu.png"
          class="layer-img" v-if="actives.is_coupon == 4" />
        <!-- 虚拟物品 -->
        <p class="hint_zh mt30" v-if="actives.is_coupon != 1 && show3">
          将于3个工作日内发放到您的账户
        </p>
        <p class="hint_en" v-if="actives.is_coupon != 1 && show3">
          Congrats! Prize will be sent in 3 working days
        </p>
        <div class="layer-btns" @click="confirmShow" v-if="show3">
          <div class="btns btn2 bigbtn">
            <span class="btn_one_ch">{{
              actives.is_coupon == 3 ? "确定" : "领取"
            }}</span>
            <span class="btn_one_en">{{
              actives.is_coupon == 3 ? "Confirm" : "Claim"
            }}</span>
          </div>
        </div>
        <div class="layer-btns" v-if="show1" @click="godetails()">
          <div class="btns btn2 bigbtn">
            <span class="btn_one_ch">去领取</span>
            <span class="btn_one_en">Claim it</span>
          </div>
        </div>
        <img src="../../assets/luckdraw_new/close.png"
          class="layer-close" @click="show3 = false; show1 = false" />
      </div>
    </div>
    <!-- 填写收货地址 -->
    <div class="layer-bg" v-if="show_page_add">
      <div class="layer">
        <div class="hint_title">填写收货地址</div>
        <div class="hint_en">Fill in your address</div>
        <div class="formItem formItemfirst">
          <div class="label">
            <div class="zh">姓名</div>
            <div class="en">Name</div>
          </div>
          <input type="text" class="value" placeholder="请输入收件人姓名" v-model="params.send_username" />
        </div>
        <div class="formItem">
          <div class="label">
            <div class="zh">手机</div>
            <div class="en">Tel</div>
          </div>
          <input type="tel" class="value" placeholder="请输入收件人手机号码" maxlength="11" v-model="params.send_phone" />
        </div>
        <div class="formItem">
          <div class="label">
            <div class="zh">城市</div>
            <div class="en">City</div>
          </div>
          <van-cell class="value" is-link @click="show_address = true">
            <span v-if="!params.send_province">所在省市区</span>
            <span style="line-height: 20px" v-else>{{ params.send_province }}{{ params.send_city
            }}{{ params.send_area }}</span>
          </van-cell>
        </div>
        <div class="formItem areatext">
          <div class="label">
            <div class="zh">详细地址</div>
            <div class="en">Address</div>
          </div>
          <textarea class="value textarea" cols="30" rows="10" placeholder="请填写详细地址"
            v-model="params.send_addr"></textarea>
        </div>
        <p class="hintL">
          请在中奖后7天内填写地址，逾期失效。
        </p>
        <p class="hintL hintL-en">
          Please fill in address in 7 days after you win the prize, otherwise the prize will turn invalid.</p>
        <div class="layer-btns ">
          <div class="btns btn2 bigbtn" @click="submit()">
            <span class="btn_one_ch">领取</span>
            <span class="btn_one_en">Claim</span>
          </div>
        </div>
        <img src="../../assets/luckdraw_new/close.png"
          class="layer-close" @click="show_page_add = false" />
      </div>
    </div>
    <!-- 活动弹窗 -->
    <div class="layer-bg" v-if="prize.first_alert == 1">
      <div class="layer popup4layer">
        <img src="../../assets/luckdraw_new/popup4.png" class="bg"/>
        <img src="../../assets/luckdraw_new/close.png"
          class="layer-close" @click="prize.first_alert = 0" />
      </div>
    </div>
    <van-popup v-model="show_address" position="bottom">
      <van-area confirm-button-text=" " cancel-button-text=" " :area-list="areaList" :value="value"
        @cancel="show_address = false" @confirm="click_address" />
    </van-popup>
    <!-- 很遗憾，未中奖 -->
    <div class="layer-bg" v-if="show2">
      <div class="layer">
        <h5 class="hint_title">很遗憾，未中奖！</h5>
        <p class="hint_en">What a pity!</p>
        <img src="../../assets/luckdraw_new/daidai3.png" class="layer-img" />
        <p class="hint_zh mt30">一直抽不到？试试积分兑礼吧</p>
        <p class="hint_en">Try other redemption</p>
        <div class="layer-btns">
          <div class="btns" @click="goback()">
            <span class="btn_one_ch">返回抽奖</span>
            <span class="btn_one_en">Back</span>
          </div>
          <div class="btns btn2" @click="go_redemption()">
            <span class="btn_one_ch">积分兑换</span>
            <span class="btn_one_en">Redemption</span>
          </div>
        </div>
        <img src="../../assets/luckdraw_new/close.png"
          class="layer-close" @click="show2 = false" />
      </div>
    </div>
    <!-- 提示语句 -->
    <div class="layer-bg" v-if="show">
      <!-- 非钻石会员 -->
      <div class="layer" v-if="diamond != 1">
        <p class="hint_zh">是否确认花费{{ score }}积分抽奖一次</p>
        <p class="hint_en" style="margin-top: 6px; font-size: 10px">
          Do you want to use {{ score }} points to redeem a chance?
        </p>
        <div class="layer-btns">
          <div class="btns" @click="show = false">
            <span class="btn_one_zh">取消</span>
            <span class="btn_one_en">Cancel</span>
          </div>
          <div class="btns btn2" @click="click_start()">
            <span class="btn_one_zh">确认</span>
            <span class="btn_one_en">Confirm</span>
          </div>
        </div>
        <img src="../../assets/luckdraw_new/close.png"
          class="layer-close" @click="show = false" />
      </div>
      <!-- 钻石会员 -->
      <div class="layer" v-if="diamond == 1">
        <p class="hint_zh">亲爱的钻石会员,您确认使用</p>
        <p class="hint_zh">{{ score }}积分兑换一次抽奖机会吗?</p>
        <p class="hint_en" style="margin-top: 6px; font-size: 10px">
          Do you want to use {{ score }} points to redeem a chance?
        </p>
        <div class="layer-btns">
          <div class="btns" @click="show = false">
            <span class="btn_one_zh">取消</span>
            <span class="btn_one_en">Cancel</span>
          </div>
          <div class="btns btn2" @click="click_start()">
            <span class="btn_one_zh">确认</span>
            <span class="btn_one_en">Confirm</span>
          </div>
        </div>
        <img src="../../assets/luckdraw_new/close.png"
          class="layer-close" @click="show = false" />
      </div>
    </div>
    <!-- 积分不足 -->
    <div class="layer-bg" v-if="show4">
      <div class="layer">
        <p class="hint_title">您当前积分不足, 请前去赚积分</p>
        <!-- <p class="hint_en">Insufficient points！</p> -->
        <div class="layer-btns">
          <div class="btns" @click="show4 = false">
            <span class="btn_one_zh">取消</span>
            <span class="btn_one_en">Cancel</span>
          </div>
          <div class="btns btn2" @click="gohint()">
            <span class="btn_one_zh">去赚积分</span>
            <span class="btn_one_en">Win points</span>
          </div>
        </div>
        <img src="../../assets/luckdraw_new/close.png"
          class="layer-close" @click="show4 = false" />
      </div>
    </div>
  </div>
</template>
<script>
var qs = require("qs");
import AreaList from "../../assets/area.js";
import wx from "weixin-js-sdk";
export default {
  name: "index",
  props: {
    msg: String,
  },
  data() {
    return {
      is_double: -1, //0正常模式，>0是双倍模式 1普通双倍  2圣诞  3新年
      diamond: "",
      areaList: AreaList,
      params: {
        ut: "",
        id: "",
        send_username: "",
        send_phone: "",
        send_province: "",
        send_city: "",
        send_area: "",
        send_addr: "",
      },
      value: "",
      show_address: false,
      show_page_add: false,
      success: false,
      show: false,
      show1: false, ///////////////////////////////////////////false
      show2: false,
      show3: false,
      show4: false,
      prize: "",
      prizelist: "",
      rule: "",
      userinfo: "",
      text: "",
      rotate: "",
      is_alert: "", //积分不足1
      show_popup: false,
      score: "",
      status: 0,
      level: 0, /////////////////////////////////////////////''
      wintheprize_name: "",
      wintheprize_img: "",
      wintheprize_number: 2,
      widthaa: "",
      actives: {},
      axiosBtn: true,
      getImage: '',
    };
  },
  created() {
    this.getImage = JSON.parse(localStorage.getItem("ALQ_getImage"))
    console.log(document.body.clientWidth);
    this.widthaa = document.body.clientWidth;
    this.heightaa = document.body.clientHeight;
    if (location.href.indexOf("?") != -1) {
      var arr = location.href.split("?")[1];
      console.log(arr);
      var arr1 = arr.split("&");
      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i].split("=")[0] == "ut") {
          localStorage.setItem("ut", arr1[i].split("=")[1]);
        }
        if (arr1[i].split("=")[0] == "userid") {
          console.log(arr1[i].split("=")[1].split("#"));
          if (arr1[i].split("=")[1].split("#")) {
            localStorage.setItem("userid", arr1[i].split("=")[1].split("#")[0]);
          } else {
            localStorage.setItem("userid", arr1[i].split("=")[1]);
          }
        }
      }
    }
    this.getprize();
    this.getUser();
    if (localStorage.getItem("addres")) {
      this.getUserAddress();
    }
  },
  methods: {
    // 去钻石会员
    godiamonds() {
      console.log("去钻石会员");
      this.$router.push({ path: "/diamonds_gift_wx_new-zh" });
    },
    // 去赚积分
    gohint() {
      console.log("去赚积分");
      this.$router.push({ path: "/H5_game" });
      // wx.miniProgram.navigateTo({
      //   url: "/packAgeA/pages/cms-pages/cms-pages?pageId=2207210003823528"
      // });
    },
    // 默认地址
    getUserAddress() {
      // 请求用户地址
      this.$axios({
        method: "post",
        url: this.$url + "/Api/User/getUserAddress",
        data: qs.stringify({
          ut: localStorage.getItem("ut"),
        }),
      }).then((res) => {
        if (res.data.code == 1) {
          var getUserAddress = res.data.data;
          localStorage.setItem(
            "getUserAddress_diamond",
            JSON.stringify(getUserAddress.diamond)
          );
          localStorage.setItem(
            "getUserAddress_normal",
            JSON.stringify(res.data.data.normal)
          );
          var getUserAddress = JSON.parse(
            localStorage.getItem("getUserAddress_normal")
          );
          console.log("getUserAddress=", getUserAddress);
          this.params.send_username = getUserAddress.send_username || "";
          this.params.send_phone = getUserAddress.send_phone || "";
          this.params.send_province = getUserAddress.send_province || "";
          this.params.send_city = getUserAddress.send_city || "";
          this.params.send_area = getUserAddress.send_area || "";
          this.params.send_addr = getUserAddress.send_addr || "";
        }
      });
    },
    go_redemption() {
      wx.miniProgram.navigateTo({
        url: "/packAgeA/pages/cms-pages/cms-pages?pageId=2106010001478253",
      });
    },
    confirmShow() {
      this.show3 = false;
      if (this.actives.is_coupon != 3) {
        localStorage.setItem("prize", JSON.stringify(this.actives));
        this.$router.push({ path: "/H5_luckdrawdetails_new" });
      }
    },
    click_address(e) {
      console.log(e);
      this.params.send_province = e[0].name;
      this.params.send_city = e[1].name;
      this.params.send_area = e[2].name;
      //this.params.code = e[2].code;
      //this.params.value = e[2].code;
      this.show_address = false;
    },
    //领取实物
    submit() {
      if (!this.params.send_username) {
        this.$toast({
          mask: true,
          message: "请输入姓名",
          zIndex: 999999,
        });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.params.send_phone)) {
        this.$toast({
          mask: true,
          message: "请输入正确的手机号",
          zIndex: 999999,
        });
        return;
      }
      if (!this.params.send_province) {
        this.$toast({
          mask: true,
          message: "请选择省市区",
          zIndex: 999999,
        });
        return;
      }
      if (!this.params.send_addr) {
        this.$toast({
          mask: true,
          message: "请输入详细地址",
          zIndex: 999999,
        });
        return;
      }
      console.log(
        JSON.stringify({
          ...this.params,
          ut: localStorage.getItem("ut"),
        })
      );
      if (!this.axiosBtn) return false
      this.axiosBtn = false
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Score/add_address",
        data: qs.stringify({
          ...this.params,
          ut: localStorage.getItem("ut"),
        }),
      }).then((res) => {
        this.axiosBtn = true
        if (res.data.code == 1) {
          this.show_page_add = false;
          this.getprize();
          this.$router.push({
            path: "/H5_luckdrawEntity_new",
            query: {
              ...this.params,
              ...this.actives,
            },
          });
        } else {
          this.$toast({
            message: res.data.msg,
            zIndex: 999999,
          });
        }

        // this.userinfo = res.data.data;
      });
    },
    goback() {
      this.show2 = false;
    },
    godetails() {
      this.getprize();
      var that = this;
      // 1.卡卷  2.优惠卷  3.积分 4.实物
      if (this.actives.is_coupon == 4) {
        if (this.is_double == 3 && this.actives.is_super == 1) {
          //锦鲤大礼包
          this.$router.push({
            path: "/luckdrawNewYear_new",
            query: { id: that.params.id },
          });
        } else {
          this.show_page_add = true;
        }
      } else {
        this.golist();
      }
      this.show1 = false;
    },
    go_list() {
      this.$router.push({ path: "/H5_luckdrawlist_new" });
    },
    getprize() {
      var data = qs.stringify({
        ut: localStorage.getItem("ut"),
        userid: localStorage.getItem("userid"),
      });
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Score/prize",
        data: data,
      }).then((res) => {
        localStorage.setItem("ScorePrize", JSON.stringify(res.data.data));
        this.prizelist = res.data.data.prize;
        this.rule = res.data.data.rule;
        this.score = res.data.data.score;
        this.status = res.data.data.status;
        this.prize = res.data.data;
        // status=0没抽过，=1第一次，=2第二次，=3第三次已经翻倍
        this.diamond = res.data.data.diamond;
        this.is_alert = res.data.data.is_alert;
        this.is_double = res.data.data.is_double;
        // this.is_double = 3;
        localStorage.setItem("is_double", this.is_double);
      });
    },
    getUser() {
      var data = qs.stringify({
        ut: localStorage.getItem("ut"),
        userid: localStorage.getItem("userid"),
      });
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Score/getUser",
        data: data,
      }).then((res) => {
        console.log(res);
        this.userinfo = res.data.data;
      });
    },
    look() {
      this.$router.push({ path: "/H5_luckdrawlist_new" });
    },
    golist() {
      var data = qs.stringify({
        ut: localStorage.getItem("ut"),
        userid: localStorage.getItem("userid"),
        prize_id: JSON.parse(localStorage.getItem("prize")).prize_id,
        id: JSON.parse(localStorage.getItem("prize")).id,
      });
      //虚拟
      this.$axios({
        method: "post",
        url: this.$url + "/Api/Score/save_info",
        data: data,
      }).then((res) => {
        console.log("save_info=", res);
        if (res.data.code == -1) {
          this.$toast({
            mask: true,
            message: res.data.msg,
            zIndex: 999999,
          });
        } else {
          console.log(res);
          this.success = true;
        }
      });
    },
    closr_popup() {
      console.log("取消");
      this.show = false;
    },
    // 确定抽奖
    click_start() {
      var that = this;
      if (!this.axiosBtn) return false
      this.axiosBtn = false
      that.show_popup = true;
      that.show = false;
      var arr = [];
      that.prizelist.forEach((e) => {
        arr.push({ id: e.id, level: e.level });
      });
      var data = qs.stringify({
        ut: localStorage.getItem("ut"),
        userid: localStorage.getItem("userid"),
        // jsonstr:JSON.stringify(arr)
        jsonstr: arr,
      });
      this.$toast({
        mask: true,
        duration: 0,
        message: "加载中...",
        zIndex: 999999,
      });

      that
        .$axios({
          method: "post",
          url: this.$url + "/Api/Score/getPrize",
          data: data,
        })
        .then((res) => {
          that.$toast.clear()
          that.getUser();
          console.log(res.data.data);
          if (res.data.code == 1) {
            localStorage.setItem("prize", JSON.stringify(res.data.data));
            that.wintheprize = res.data.data;
            that.wintheprize_img = res.data.data.img_url;
            that.wintheprize_number = res.data.data.number;
            that.level = res.data.data.level;
            that.rotate =
              3600 +
              parseInt(Math.random() * 25 + 5) +
              36 * (res.data.data.level - 1);
            this.params.id = res.data.data.id;
            this.actives = res.data.data;
          } else {
            this.axiosBtn = true
            that.$toast({
              mask: true,
              message: res.data.msg,
              zIndex: 999999,
            });
            that.show_popup = false;
          }
          console.log(this.rotate);
          that.$refs.turntable.style.transform =
            "rotate(" + this.rotate + "deg)";
          that.$refs.turntable.style.transition = "all 5s";

          setTimeout(() => {
            that.show_popup = true;
            //1.卡卷  2.优惠卷  3.积分 4.实物
            if (this.actives.is_coupon == 1) {
              that.show3 = true;
            } else if (this.actives.is_coupon == 2) {
              that.show3 = true;
            } else if (this.actives.is_coupon == 3) {
              that.show3 = true;
            } else if (this.actives.is_coupon == 4) {
              that.show1 = true;
            }
            setTimeout(() => {
              console.log("恢复");
              this.axiosBtn = true
              that.show_popup = false;
              that.$refs.turntable.style.transition = "all 0s";
              that.$refs.turntable.style.transform = "rotate(0deg)";
              this.getprize();
            }, 1000);
          }, 5500);
        });
    },
    start_game() {
      var that = this;
      that.show = true;
    },
  },
  filters: {
    filter_length(e) {
      return e.substring(0, 6);
    },
  },
};
</script>

<style scoped >
@import"../../assets/H5.css";

.status-d {
  box-sizing: border-box;
  width: 553px;
  position: absolute;
  top: 227px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 93px;
}

.status-dbg {
  width: 555px;
  height: 35px;
  position: absolute;
  left: 0;
  top: 0;
}

.status-li {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  color: #00005F;
  position: relative;
}

.status-li img {
  width: 13px;
  height: 13px;
  margin-bottom: 5px;
}

.status-d .yes-text {
  position: absolute;
  top: -56px;
  right: 0;
  width: 85px;
  height: 45px;
}

.hintL {
  text-align: left;
  width: 100%;
  font-size: 26px;
}

.hintL-en {
  font-size: 20px;
  margin-top: 13px;
}

.layer-img {
  width: 110px;
  height: auto;
  margin-top: 75px;
}

.mt30 {
  margin-top: 30px;
}

.van-picker__cancel {}

.formItem {
  box-sizing: border-box;
  width: 576px;
  height: 76px;
  background: #EDEDFA;
  box-shadow: 0px 4px 56px 0px rgba(0, 0, 0, 0.0400);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 30px;
}

.formItemfirst {
  margin-top: 47px;
}

.label {
  display: flex;
  flex-direction: column;
}

.label .zh {
  font-size: 26px;
}

.label .en {
  line-height: 1;
  font-size: 18px;
}

.value {
  font-size: 26px;
  text-align: right;
  flex: 1;
  margin-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  background: none;
  border: none;
}

.wintheprize_tit {
  font-weight: bold;
  font-size: 32px;
}

.areatext {
  box-sizing: border-box;
  height: 158px;
  align-items: flex-start;
  padding-top: 17px;
}

.textarea {
  padding-top: 11px;
  height: 75%;
}

.value span {
  display: block;
  flex: 1;
  text-align: right;
}

.layer {
  box-sizing: border-box;
  width: 640px;
  padding: 48px 57px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}

.hint_title {
  font-size: 32px;
  font-weight: bold;
}

.hint_zh {
  font-size: 26px;
  font-weight: normal;
}

.layer-close {
  position: absolute;
  bottom: -120px;
  width: 62px;
  height: 62px;
}

.layer-btns {
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer-btns .btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px 0px 20px 0px;
  margin: 0 31px;
  align-items: center;
  width: 232px;
  height: 72px;
  border: 2px solid #00005F;
  color: #00005F;
  line-height: 1;
}

.layer-btns .bigbtn {
  width: 312px;
  height: 72px;
}

.layer-btns .btn2 {
  background: #00005F;
  color: #fff;
}

.btn_one_zh {
  font-size: 28px;
  font-weight: normal;
}

.btn_one_en {
  font-size: 22px;
  font-weight: normal;
}

.luckdraw {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
}

.luckydraw_log {
  position: absolute;
  top: 24px;
  right: 32px;
  width: 136px;
  height: auto;
}

.luckydraw_log .en {
  font-size: 10px;
}

.luckdraw_bg {
  width: 100%;
}

.luckbg {
  width: 100%;
}

.luckdraw_box {
  width: 650px;
  height: 650px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 312px;
  left: calc(50% - 325px);
  overflow: hidden;
  border-radius: 50%;
}

.luckdraw_box2 {
  top: 341px;
}

.animation_box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.luckdraw_pan {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.luckdraw_btn {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 232px;
  z-index: 2;
}

.my_jf_d {
  background-image: url("../../assets/luckdraw_new/jifen-d.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 742px;
  height: 395px;
  position: absolute;
  top: 1008px;
  left: 0px;
}


.my_jf {
  font-size: 72px;
  position: absolute;
  left: 279px;
  top: 62px;
  color: #00005F;
}

.my_hint {
  position: absolute;
  top: 75px;
  right: 74px;
  width: 134px;
  height: auto;
}

.my_hint .en {
  font-size: 10px;
}

.godiamonds {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 120px;
  left: 40px;
}

.rule-d {
  background-image: url("../../assets/luckdraw_new/hint-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 750px;
  height: 576px;
  position: absolute;
  top: 1468px;
  ;
  font-size: 28px;
  color: rgba(23, 26, 29, 1);
  line-height: 40px;

  display: flex;
  justify-content: center;
}

.rule {
  width: 607px;
  height: 390px;
  margin-top: 107px;
  font-size: 28px;
  color: rgba(23, 26, 29, 1);
  line-height: 40px;
  overflow-y: scroll;

}

.popup4layer {
  width: 560px;
  height: 640px;
  padding: 0;
  background: none;
}

.popup4layer .bg {
  width: 100%;
  height: auto;
}
</style>
<style>
.van-dialog {
  width: 640px !important;
  box-sizing: border-box;
  padding: 44px 55px;
}

.van-dialog__message {
  font-size: 20px !important;
  font-weight: normal;
  color: rgba(23, 26, 29, 1);
  text-align: center;
  margin: 0px 0 80px !important;
}

.van-dialog__footer--buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.van-picker__cancel {
  width: 118px !important;
  height: 42px !important;
  background-image: url("../../assets/luckdraw_new/add_no.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: 30px;
}

/* van-button van-button--default van-button--large van-dialog__confirm van-hairline--left */
.van-picker__confirm {
  width: 118px !important;
  height: 42px !important;
  background-image: url("../../assets/luckdraw_new/add_yes.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 30px;
}

.luckdraw .van-dialog__content {
  text-align: center;
}

.luckdraw .van-dialog__footer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.van-dialog__header {
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: normal;
  color: rgba(23, 26, 29, 1);
}

.popup_wintheprize .van-dialog__confirm {
  width: 306px !important;
  max-width: 306px !important;
  height: 78px;
  background: rgba(107, 218, 250, 1);
  border-radius: 8px;
}</style>
  